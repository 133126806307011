import React, { useState, useEffect, useRef } from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';

const Header = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchRef = useRef(null);

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setIsSearchOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark custom-navbar">
      <div className="container-fluid">
        <a className="navbar-brand d-flex align-items-center" href="#">
          {/* <img src="/images/logo.svg" alt="Brand Logo" className="brand-logo" /> */}
          <h2>SHUFFLE</h2>
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto me-auto mb-2 mb-lg-0 gap-3 mx-3">
            <li className="nav-item">
              <NavLink className="nav-link active" to="/">Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/explore">Explore</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/bytes">Bytes</NavLink>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">Wallet</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">Settings</a>
            </li>
          </ul>
          <div className="header-login-lets-talk gap-4" ref={searchRef}>
            <button className='search-button' onClick={toggleSearch}><RiSearchLine /></button>
            {isSearchOpen && (
              <input type="text" className="search-bar" placeholder="Search" />
            )}
            <button className="btn ms-2 login-button">Login</button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
