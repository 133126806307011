import React from 'react';
import { MdVerified } from 'react-icons/md';
const TrendingComponent = () => {
    return (
        <div className="trending-component">
            <h3 className='text-white px-3'>News & Trending</h3>
            <div className='trending-container'>
                <div class="trending-item large">
                    <img src="/images/large-bg.svg" alt="Image" class="zorb-image" />
                    <div class="content">
                        <h1>Welcome to Tape</h1>
                        <p>Discover the new era of media sharing on Lens with Tape. A decentralized, user-centric approach to online media.</p>
                    </div>
                    <div class="button-container">
                        <button>Login</button>
                    </div>
                </div>
                <div class="trending-item medium">
                    <img src="/images/zorb.webp" alt="Zorb Image" class="zorb-image" />
                    <div class="content">
                        <h1>Mint your free Tape Zorb!</h1>
                    </div>
                    <div class="button-container">
                        <button>Mint</button>
                    </div>
                </div>
                <div className='trending-item-small'>
                    <div className="trending-item small">
                        <img src="/images/small-card-bg.webp" className='img-fluid' alt="Kraken" />
                        <p>It's crucial to gather feedback on your product...</p>
                    </div>
                    <div className='user-name'>Kraken <span className='verified-icon'> <MdVerified /> </span></div>
                </div>
                <div className='trending-item-small'>
                    <div className="trending-item small">
                        <img src="/images/small-card-bg.webp" className='img-fluid' alt="Kraken" />
                        <p>ese cuerpo calienta</p>
                    </div>
                    <div className='user-name'>genwav <span className='verified-icon'> <MdVerified /> </span></div>
                </div>
                <div className='trending-item-small'>
                    <div className="trending-item small">
                        <img src="/images/small-card-bg.webp" className='img-fluid' alt="Kraken" />
                        <p>What happened to N...</p>
                    </div>
                    <div className='user-name'>kellykim <span className='verified-icon'> <MdVerified /> </span></div>
                </div>
            </div>
        </div>
    );
};

export default TrendingComponent;
